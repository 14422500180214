<template>
    <div>
        <page-header pageTitle="GENERAL.MSG.PROGRESS_REGISTER_CLIENTS"
        ></page-header>
        <div class="card p-2" style="height: 74vh">
            <div id="apex" style="height: 50vh"></div>
        </div>
    </div>
</template>

<script>
import pageHeader from "../../components/layouts/pageHeader";
import Clients from "../../services/Clients";
import * as ApexCharts from "apexcharts";
import {useToast} from "vue-toastification";
import {useI18n} from "vue-i18n";

export default {
    name: "dashboardClient",
    components: {
        pageHeader,
    },
    setup() {
        const toast = useToast();
        const {t} = useI18n();
        return {t, toast}
    },
    beforeRouteLeave(to, from, next) {
        if (this.chart) {
            this.chart.destroy();
            this.chart = null;
        }
        next();
    },
    data() {
        return {
            items: null,
            filters: null,
            chart: null,
        }
    },
    mounted() {
        this.renderChart();
    },
    methods: {
        async index() {
            await Clients.dashboard().then(resp => {
                this.dateRegisters = resp.data.dateRegisters;
                this.seriesData = resp.data.seriesData;
            }).catch(error => {
                console.error("Failed to fetch data:", error);
                this.toast.error("Failed to fetch data.");
            });
        },
        async search(filter) {
            this.$store.commit('changeLoading', true);
            if (this.chart) {
                this.chart.destroy();
                this.chart = null;
            }
            this.filters = {filtro: filter};
            await this.renderChart();
            this.$store.commit('changeLoading', false);
        },
        async renderChart() {
            this.$store.commit('changeLoading', true);
            await this.index();

            // Verifica se há dados para o gráfico
            if (!this.seriesData || this.seriesData.length === 0) {
                this.$store.commit('changeLoading', false);
                return;
            }

            const formattedDates = this.dateRegisters;
            // Configurações do gráfico
            const options = {
                series: this.seriesData,
                chart: {
                    type: 'bar',
                    height: '85%',
                    stacked: true,
                    toolbar: {
                        show: true,
                        offsetX: 0,
                        offsetY: 0,
                        tools: {
                            download: true,
                            selection: false,
                            zoom: true,
                            zoomin: true,
                            zoomout: true,
                            pan: false,
                            reset: true | '<img src="/static/icons/reset.png" width="20">',
                            customIcons: []
                        },
                        autoSelected: 'zoom'
                    },
                    zoom: {
                        enabled: true
                    },
                    selection: {
                        enabled: true
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        endingShape: "rounded",
                        borderRadius: 0,
                        dataLabels: {
                            total: {
                                enabled: true,
                                style: {
                                    fontSize: '13px',
                                    fontWeight: 900
                                }
                            }
                        }
                    }
                },
                xaxis: {
                    type: 'category',
                    tickPlacement: 'on',
                    categories: formattedDates,
                    labels: {
                        show: true,
                        formatter: (date) => {
                            if (!date) {
                                return '-'
                            }
                            date = date.split('-')
                            return `${date[2]}/${date[1]}/${date[0]}`;
                        },
                    }
                },
                legend: {
                    position: 'bottom',
                    offsetY: 10,
                    showForSingleSeries: true,
                },
                fill: {
                    opacity: 1
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        legend: {
                            position: 'bottom',
                            offsetX: -10,
                            offsetY: 0
                        }
                    }
                }]
            };

            const el = document.getElementById('apex');
            if (el) {
                this.chart = new ApexCharts(el, options);
                this.chart.render();
            } else {
                console.error("Element with id 'apex' not found");
            }

            this.$store.commit('changeLoading', false);
        }
    }

}
</script>

<style scoped>
.apexcharts-legend {
    justify-content: start !important;
}
</style>
